import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header className="footer">
      <div className="container">
             
        <div className="cta">
          
        </div>
        <br></br>
        <div> HDEVS - DESARROLLOS INFORMÁTICOS - Río Ceballos - Córdoba Argentina</div>
          
          
      </div>
    </header>
  );
};

export default Header;