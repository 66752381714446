import React from "react";
import "./Contacto.css";


const contacto = [
  
  { 
    id: 1, 
    title: "", 
    description: "Complete sus datos y un asesor especializado se pondrá en contacto con usted a la brevedad para brindarle toda la información que necesita. ¡Estamos aquí para ayudarle a encontrar la solución ideal!", 
    logos: [
      "portal2.jpg",
      
    ] 
  },
 
  
];

const ContactoSection = () => {
  return (
    <section id="contact" className="contact-section">
    <div className="container">
      <h2>Contáctenos (SITIO EN CONTRUCCION)</h2>
      <div className="services-grid-contact">
        {contacto.map(service => (
          <div key={service.id} className="service-card-contact">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <div className="input-container">
              <input type="text" placeholder="Nombre" className="contact-input" />
              <br/>
              <input type="email" placeholder="Correo electrónico" className="contact-input" />
              <br/>
              <textarea placeholder="Mensaje" className="contact-textarea"></textarea>
              <br/>
              <button className="contact-button" onClick={handleSubmit}>Enviar</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
    
  );
};

const handleSubmit = (e) => {
  e.preventDefault(); // Evita la recarga de la página
  console.log("Formulario enviado"); // Aquí puedes manejar el envío
};

export default ContactoSection;

