import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
    <div className="header-container">
      <div className="logo">
        <a href="/">
          <img src="/assets/images/logo2.jpg" alt="Logo" className="logo-img" />
          HDEVS | DESARROLLOS INFORMÁTICOS
        </a>
      </div>
      <nav className="navigation">
        <ul>
          <li><a href="#services">Servicios</a></li>
          <li><a href="#about">Nosotros</a></li>
          <li><a href="#contact">Contacto</a></li>
          <li><a href="#apps">Aplicaciones</a></li>
        </ul>
      </nav>
      <div className="cta">
        <button>Solicitar una demostración</button>
      </div>
    </div>
  </header>
  );
};

export default Header;
