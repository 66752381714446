import React from "react";
import "./NuestrasApps.css";


const appsWeb = [
  
  { 
    id: 2, 
    title: "Formularios Digitales", 
    description: "Desarrollo e implementación de soluciones integrales para la digitalización de formularios y la automatización de procesos de negocio. Estas soluciones están diseñadas para optimizar la recolección, gestión y análisis de datos, mientras garantizan el cumplimiento de cada paso en los flujos de trabajo establecidos.", 
    logos: [
      "portal44.jpg",
      
    ] 
  },
  { 
    id: 2, 
    title: "Permisos de Trabajo Digitales", 
    description: "Digitalización de formularios y procesos de negocio implementando notificacion y controles para asegurar el cumplimiento de todos los pasos.", 
    logos: [
      "portal1.jpg",
      
    ] 
  },
  { 
    id: 2, 
    title: "Capacitación y evaluación para análisis de riesgos", 
    description: "Digitalización de formularios y procesos de negocio implementando notificacion y controles para asegurar el cumplimiento de todos los pasos.", 
    logos: [
      "portal4.jpg",
    ] 
  },
];

const NuestrasApps = () => {
  return (
    <section id="apps" className="apps-section">
      <div className="container">
        <h2>Nuestras Aplicaciones Desarrolladas (SITIO EN CONTRUCCION)</h2>
        <div>En nuestros proyectos desarrollamos soluciones personalizadas adaptadas a las necesidades de nuestros clientes. Ya sea creando aplicaciones a medida según sus requerimientos específicos o evaluando sus procesos para proponer soluciones integrales que optimicen su productividad. Nuestro enfoque combina innovación, eficiencia y resultados concretos para garantizar su satisfacción.</div>

        <div className="services-grid-app">
          {appsWeb.map(service => (
            <div key={service.id} className="service-card-app">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <div className="logos-container">
                {service.logos.map((logo, index) => (
                  <img 
                    key={index} 
                    src={`/assets/images/${logo}`} 
                    alt={`${logo.split("-")[0]} logo`} 
                    className="service-logo-app1" 
                  />
                ))}
              </div>
            </div>
          ))}
           
        </div>
      </div>
    </section>
  );
};
export default NuestrasApps;

