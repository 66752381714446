import React from "react";

const WhatsAppButton = () => {
  const phoneNumber = "5493513574866"; // Reemplaza con tu número de WhatsApp (sin + ni espacios)
  const message = "Hola, me gustaría obtener más información"; // Mensaje predeterminado

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      style={styles.button}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        style={styles.icon}
      />
    </a>
  );
};

const styles = {
  button: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#25D366",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    zIndex: 1000,
  },
  icon: {
    width: "35px",
    height: "35px",
  },
};

export default WhatsAppButton;
